@import '~bootstrap/scss/functions';

// Bootstrap Variables
$primary: #08475E;
$body-color: #2B2523;
$white: #fff;

// Custom Colors
$blizzard-blue: #B1E5F2;
$persian-pink: #FF88DC;
$light-pink: #F4AFB4;
$dark-orange: #FA8E08;
$mustard: #FFDD54;

// Custom variables from custom classes
$button-bg-color: $mustard;
$button-bg-hover-color: $dark-orange;

// Table warning colors
// table-danger -> Company/Product Link - Instruction manual - image needed
$all-needed: $light-pink;
$img-instruction-warning: lighten($dark-orange, 20%); 
$image-warning: lighten($mustard, 20%);
$instruction-warning: $persian-pink;
$prod-comp-link-warning: $blizzard-blue;  // Company/Product website link needed

// Custom theme colors to default theme colors
$custom-theme-colors: (
    "all-needed-warning": $all-needed,
    "image-instruction-warning": $img-instruction-warning,
    "image-warning": $image-warning,
    "instruction-warning": $instruction-warning,
    "prod-comp-link-warning": $prod-comp-link-warning,
    "white": $white,
);

$link-color: $primary;
$link-hover-color: $button-bg-hover-color;
$border-color: lighten($primary, 20%);
$button-hover-text-color: $white;
$input-border-color: lighten($primary, 20%);

// Progressbar styles
$progress-height: 1.5rem;
$progress-font-size: 1rem;

@import '~bootstrap/scss/variables';

// Using the code below, custom theme colors has been merged with default Bootstrap theme colors
$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$colors: map-merge($colors, $custom-theme-colors);
// Add custom theme colors to default table colors
$table-variants: map-merge($table-variants, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// Merging maps for color utilities generation for background text utilities
$utilities-colors: map-merge($utilities-colors, $theme-colors);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// Bootstrap has $gray-300 and $gray-200 like color variables. They can be used for section background colors
@import '~bootstrap/scss/bootstrap.scss';

// import font awesome
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';

// Custom Styles
html, body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.navbar {
    background-color: $white;
    padding: 10px 0;

    .navbar-brand {
        margin-right: .4rem;
    }

    .logo {
        height: 50px;
    }

    a {
        color: $primary;
        text-transform: uppercase;

        &.link-active {
            color: $dark-orange;
        }

        &:hover {
            color: $button-bg-hover-color;
        }
    }
}

main {
    flex-grow: 1;
    padding: 0 0 40px 0;
}

.auth-form-container {
    height: 100%;
}

.badge {
    font-size: .8rem;
}

footer {
    min-height: 80px;
    background-color: $gray-300;
}

// Hero part of the pages
.hero {
    height: 200px;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert{
    margin-bottom: 0;
}

// This is for laravel-vue-pagination component since it is still for bootstrap 4
// Class name has changed since Bootstrap 5
.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.btn-edit {
    background-color: $button-bg-color;

    &:hover {
        background-color: $button-bg-hover-color;
        color: $white;
    }
}

.btn-primary:hover {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 10%);
}

// Responsive
.pagination {
    flex-wrap: wrap;
}

.sort-column i {
    font-size: 1.1rem;
    cursor: pointer;
}

/* those two properties below change the mobile icon */
.custom-toggler.navbar-toggler {
    border-color: $primary;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(16, 24, 122)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.last-update-notice {
    padding-top: 1.5rem;
}

// Progressbar animations with Bootstrap Progressbar component
@keyframes progressBarAnimation {
    from {
        width: 0%
    }
}

.progress-less-equal25, .progress-between-2530, .progress-high-equal30, .progress-total-sens {
    animation: progressBarAnimation 1.5s;
}

// Page overlay for save functionality
.page-overlay {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 110;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    visibility: hidden;
    opacity: 0;
    transform: scale(1.09);
    transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }

    &--notification {
        background-color: $gray-100;
        padding: .3rem;
    }
}

// Table Color
.color-legend {
    span {
        width: 15px;
        height: 15px;
    }
}

.table-row-selected {
    border-width: 3px !important;
    border-color: $primary;
}

.big-flag {
    width: 60px;
    height: 60px;
}

.flag {
    width: 40px;
    height: 40px;
}

.small-flag {
    width: 25px;
    height: 25px;
}

.custom-fade-in {
    opacity: 1;
    min-height: 26px;
    transition: opacity 500ms;
}

.custom-fade-out {
    opacity: 0;
    min-height: 0px;
    transition: opacity 500ms;
}

/* Cookie Consent Begin */
#cookieConsent {
    background-color: $primary;
    min-height: 26px;
    font-size: 14px;
    color: #ccc;
    line-height: 26px;
    padding: 8px 0 8px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    text-align: center;
}

#cookieConsent a {
    color: $dark-orange;
    text-decoration: none;
}

#closeCookieConsent {
    display: inline-block;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: -15px 0 0 0;
    font-weight: bold;
}

#closeCookieConsent:hover {
    color: #FFF;
}

#cookieConsent .cookieConsentOK {
    background-color: $dark-orange;
    color: white;
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
    margin: 0 60px 0 10px;
    transition: background-color 650ms;
    outline: none;
}

#cookieConsent .cookieConsentOK:hover {
    background-color: lighten($dark-orange, 10%);
}
/* Cookie Consent End */

/* CK editor styles */
.ck-editor__editable {
    min-height: 400px;
}

/* User generated content styles */
.icon-box {
    min-height: 11rem;

    a {
        text-decoration: none;
    }

    i {
        font-size: 3rem;
        color: $dark-orange;
    }
}

@media screen and (max-width: 992px) {
    .navbar {
        width: 100%;
        z-index: 9999;
    }

    .last-update-notice {
        padding-top: 3rem;
    }
}

// Responsive rules
@media screen and (max-width: 768px) {
    .pagination li {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 576px) {
    .card-body, .card-footer {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar .logo {
        height: 40px;
    }

    main {
        padding: 0;
    }
}